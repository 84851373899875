import '@babel/polyfill';
import App from "./login/App.svelte";



new App({
    target: document.getElementById('login-modal'),
    props: {
        name: 'world'
    }
});
