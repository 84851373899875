<script lang="ts">
    import {LoginHandler} from "../login";
    import PasswordLogin from "./PasswordLogin.svelte";
    import WebAuthn from "./WebAuthn.svelte";
    //export let challenge: string = '';
    export let login: () => void;

    const handleLogin = () => {
        login();
    }

    const dummy = () => {}
    $: {
    }
</script>
<form name="loginForm" on:submit|preventDefault={dummy}>
    <button on:click={handleLogin}>Aloita 2-vaiheinen tunnistautuminen</button>
</form>
<style>
</style>

