<script lang="ts">
    import { onMount } from 'svelte';

    let email = '';
    let password = '';
    let input;

    export let login: (user, pass, remember) => void;

    $: valid = (email != '') && (password !== '');

    const handleClick = () => {
        console.log('click!', email, password);
    }

    $: handleUpdate = () => {
        console.log('handleUpdate!', email, password);
    }

    const handleSubmit = (event) => {
        console.log('submit', event);
        login(email, password, false);
    }

    onMount(() => {
        setTimeout(() => {
            console.log('input', input.value);
            input.dispatchEvent(new Event('change'));
            if (input.value.length > 0) {
                email = input.value;
            }
        }, 250);
    })

    $: {
    }
</script>

<form name="loginForm" on:submit|preventDefault={handleSubmit}>
    <input type="text" name="email" bind:value={email} bind:this={input}>
    <input type="password" name="password" bind:value={password}>
    <button on:click|once={handleClick}>Kirjaudu sisään</button>
    <!--        Valid: {valid},{email},{password}-->
</form>

<style>
    input {
        padding: 16px 10px 16px 10px;
        height: 44px;
        font-size: 16px;
        margin-top: 10px;
        border: 2px solid #d9d9d9;
        border-top: 1px solid #c0c0c0;
        /* border-radius: 2px; */
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        appearance: none;
        width: 100%;
        border-radius: 0.25em;
    }
    button {
        margin-left: auto;
        margin-right: auto;
        font-size: 16px;
        line-height: 1.20!important;
        font-weight: 600;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: 0em;
        border-radius: 0.3em;
        padding: 0.9em 1.8em;
        background: linear-gradient(95deg,#1c057a,#a04bf9);
        border-color: transparent;
        color: #ffffff !important;
        box-shadow: 0 0em 0em rgba(0,0,0,0.2);
    }

    form {
        padding: 2em;
    }


</style>

